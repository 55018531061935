.container {
  background-color: #0949CA;
  min-height: 100vh;

  &::-webkit-scrollbar {
    display: none;
  }
}


.container_content {
  display: flex;
  overflow-y: scroll;
  display: -webkit-box;
  display: -webkit-flex;
  -webkit-overflow-scrolling: touch;
  flex-direction: column;
  padding-bottom: calc(66px + 16px);
  box-sizing: border-box;
  background-color: #0949CA;
}

.personal_avatar_info {
  width: 100%;
  overflow: hidden;
  background-color: transparent;

  .avatar_img {
    img {
      width: 100%;
      object-fit: contain;
    }
  }

  .logo {
    position: absolute;
    padding: 16px;
    top: 0;
    right: 0;

    .img {
      width: 140px;
    }
  }
}

.name_position_detail_info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #0949CA;
  //background-color: red;
  height: 56px;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 18px;
  color: white;

  .name_and_position_title {
    width: 40%;
    // Names
    .names {
      display: flex;
      flex-direction: column;

      .user_name {
        font-size: 36px;
        font-weight: bold;
        font-family: OPPOSans-Bold, serif;
      }
    }
  }

  // Center Splitter
  .split_line {
    width: 1px;
    height: 80%;
    background-color: white;
  }

  // Position and Office Location
  .position_titles {
    display: flex;
    width: calc(50% - 1px);
    flex-direction: column;
    justify-content: center;

    // Row 0
    .location {
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        width: 28px;
        height: 28px;
        //object-fit: contain;
      }

      span {
        font-size: 20px;
        font-weight: bold;
      }
    }

    // Row 2
    .cn_position_name {
      font-size: 22px;
      font-weight: bold;
      margin-top: 4px;
    }

    .en_position_name {
      margin-top: 4px;
      font-size: 24px;
      font-weight: bold;
    }
  }
}

.center_splitter {
  height: 1px;
  width: calc(100% - 32px);
  margin: 16px;
  background-color: white;
}

.contacts_detail_info {
  display: flex;
  position: relative;
  background-color: #0949CA;
  flex-direction: column;
  margin-left: 16px;
  margin-right: 16px;
  color: white;
  padding-left: 16px;
  padding-right: 16px;

  .item_large {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    .text {
      display: flex;
      flex-direction: row;

      span {
        font-size: 16px;
        font-weight: normal;
        margin-left: 8px;
      }

      img {
        width: 24px;
        height: 24px;
      }
    }

    .value {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 60%;
      align-items: flex-start;

      span {
        font-size: 18px;
        font-family: OPPOSans-Medium, sans-serif;
        font-weight: 500;
      }

      .small_span {
        font-size: 13px;
      }

      a {
        color: white;
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

}


.bottom_control {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  bottom: 0;
  height: 66px;
  width: 100%;
  align-items: center;
  //background-color: #F9C626;
  background-color: #FFF;

  .split_line {
    width: 1px;
    height: 40%;
    background-color: #0949CA;
  }

  .btn {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    border: none;
    //background-color: #F9C626;
    background-color: white;
    justify-content: center;
    color: #0949CA;

    .text {
      font-size: 14px;
      font-weight: 500;
      margin-left: 8px;
    }

    //btn
  }

  .btn:active {
    background-color: #EEEEEE;
  }
}

// QRCode
.qrcode_and_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin-top: 8px;

  .qr_img {
    display: flex;
    width: 40%;
    box-sizing: border-box;

    img {
      width: 60%;
      //transform: scale(0.5);
    }
  }

  .title {
    display: flex;
    width: 60%;
    justify-content: flex-start;
    align-items: center;

    span {
      font-family: OPPOSans-Bold, serif;
      width: 100%;
      font-size: 28px;
      font-weight: bold;
    }
  }

  // qrcode
}

.lang_popup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .title {
    padding-top: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 280px;
    }
  }

  .tips {
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    box-sizing: border-box;

    span {
      font-size: 18px;
      text-align: center;
      padding-bottom: 8px;
      box-sizing: border-box;
    }
  }

  .control {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 340px;
    width: calc(100vw - 32px);
    padding: 32px;
    box-sizing: border-box;

    button {
      height: 144px;
      width: 144px;
      border-radius: 16px;
      background: #EEEEEE;
      color: #0949CA;
      border: none;
      font-size: 28px;
      padding: 0;
    }

    button:active {
      background: #0949CA33;
    }
  }


}
