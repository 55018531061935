@font-face {
    font-family: OPPOSans-Regular;
    src: url("./asserts/fonts/OPPOSans-Regular.ttf");
}

@font-face {
    font-family: OPPOSans-Medium;
    src: url("./asserts/fonts/OPPOSans-Medium.ttf");
}

@font-face {
    font-family: OPPOSans-Bold;
    src: url("./asserts/fonts/OPPOSans-Bold.ttf");
}

* {
    font-family: OPPOSans-Regular, sans-serif;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
